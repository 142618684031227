<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">审核管理</span>
        <!-- 面包屑 -->
        <div class="crumbs">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/AuditManage/CoursePurchase"
            >课程购买审核 /
          </router-link>
          <span class="crumbs_item crumbs_last">详情</span>
        </div>
      </div>
    </div>
    <div class="all_content_box">
      <div class="Up_Down_inner">
        <span class="all_left_name">用户信息</span>
        <div class="bgDiv">
          <div class="line_item">
            <p>
              姓名：<span>{{ data.name }}</span>
            </p>
            <p>
              用户名：<span>{{ data.userName }}</span>
            </p>
            <p>
              角色：<span>{{ data.userType }}</span>
            </p>
          </div>
          <div class="line_item">
            <p>
              身份证号：<span>{{ data.idNo }}</span>
            </p>
            <p>
              籍贯：<span>{{ data.pgAddress }}</span>
            </p>
            <p>
              民族：<span>{{ data.nation }}</span>
            </p>
          </div>
          <div class="line_item">
            <p>
              学历：<span>{{ data.degree }}</span>
            </p>
            <p>
              性别：<span>{{
                data.sex == 1 ? "男" : data.sex == 2 ? "女" : "未知"
              }}</span>
            </p>
            <p>
              邮箱：<span>{{ data.email }}</span>
            </p>
          </div>
          <div class="line_item">
            <p>
              手机号：<span>{{ data.mobile }}</span>
            </p>
            <p>
              所在地：<span>{{ data.address }}</span>
            </p>
            <p>
              所在行业：<span>{{ data.industry }}</span>
            </p>
          </div>
          <div class="line_item">
            <p>
              职业：<span>{{ data.occupation }}</span>
            </p>
            <p>
              工作单位：<span>{{ data.workCom }}</span>
            </p>
            <p>
              工作年限：<span>{{ data.workYear }}</span>
            </p>
          </div>
          <div class="line_item">
            <p>
              报名时间：<span>{{ data.signupTime }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="Up_Down_inner">
        <span class="all_left_name">相关证书</span>
        <div class="bgDiv certificateDiv" v-viewer>
          <!-- <img src="https://hxclass.cn/static/img/404.335d48c8.jpg" alt=""> -->
          <img :src="data.relevantProof" alt="" />
          <div class="line_item" style="margin-bottom: 0">
            <p style="width: 100%">
              证书类型：<span>{{ data.certifTempName }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="Up_Down_inner">
        <span class="all_left_name">补充说明</span>
        <div class="bgDiv">
          <div class="line_item" style="margin-bottom: 0">
            <p>{{ data.content }}</p>
          </div>
        </div>
      </div>
      <div class="Up_Down_inner">
        <span class="all_left_name">相关文件</span>
        <div class="bgDiv">
          <!-- <span>relevantFile个人孤独症内容.pdf</span> -->
          <a href="" :download="data.relevantFile">{{
            data.relevantFileName
          }}</a>
        </div>
      </div>
      <div class="Up_Down_inner">
        <span class="all_left_name">审核状态：</span>
        <span class="all_left_name">{{
          data.status == 1 ? "待审核" : data.status == 4 ? "已驳回" : "已通过"
        }}</span>
        <span
          v-if="data.status == 4"
          class="all_left_name"
          style="margin-left: 24px"
          >驳回原因：</span
        >
        <span v-if="data.status == 4" class="all_left_name">{{
          data.rejectReason
        }}</span>
      </div>
      <!-- <div class="Up_Down_inner" v-if="data.status != 4">
        <span class="check">
          <a-checkbox
            :disabled="data.status != 1"
            :checked="checked"
            @change="onChange"
            >上传证件库</a-checkbox
          ><i v-if="data.status == 1"
            >（是否上传证件库，如需上传请勾选）</i
          ></span
        >
      </div> -->
      <div class="foot_btn">
        <a-button
          v-if="data.status == 1"
          v-hasPermi="['pay:audit:updatestatus']"
          type="primary"
          class="btn"
          @click="pass = true"
          >通过</a-button
        >
        <!-- <a-button  @click="edit = true" class="btn">驳回</a-button> -->
        <a-button
          v-if="data.status == 1"
          v-hasPermi="['pay:audit:updatestatus']"
          class="all_boder_btn btn"
          @click="edit = true"
          >驳回</a-button
        >
        <a-button v-if="data.status != 1" class="all_boder_btn"
          ><router-link
            class="crumbs_item"
            tag="a"
            to="/admin/AuditManage/CoursePurchase"
            >返回</router-link
          ></a-button
        >
      </div>

      <!-- 通过 -->
      <a-modal v-model="pass" align="center" width="800px" title="通过">
        <div class="dlex">
          <div class="image">
            <img
              :style="{ transform: `rotate(${rotation}deg)` }"
              :src="data.relevantProof"
              alt=""
            />
            <a-button class="btnforat" @click="rotateImage"
              >点击向右旋转</a-button
            >
          </div>
          <div class="form">
            <a-form
              :form="form"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 19 }"
            >
              <a-row>
                <a-col :span="11">
                  <a-form-item label="证书编号">
                    <a-input v-model="certificateCode" />
                  </a-form-item>
                </a-col>
                <a-col :span="1">
                  <span> </span>
                </a-col>
                <a-col :span="11">
                  <a-form-item label="发证日期">
                    <a-month-picker
                      format="YYYY-MM"
                      @change="ondateChange"
                      v-model="certificateDate"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <div class="dlexname">
                <span>证书模板：</span>
                <a-form-item style="width: 600px" label="">
                  <a-select
                    @change="handleChange"
                    placeholder="选择证书模板"
                    v-model="certifTempId"
                  >
                    <a-select-option
                      v-for="(item, index) in tableData"
                      :key="index"
                      :value="item.tempId"
                    >
                      {{ item.certifName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
            </a-form>
          </div>
        </div>

        <template slot="footer">
          <a-button type="primary" html-type="submit" @click="passOk"
            >确认</a-button
          >
          <a-button @click="passCancel">取消</a-button>
        </template>
      </a-modal>
      <!-- 驳回原因 -->
      <a-modal v-model="edit" align="center" title="驳回">
        <a-textarea
          v-model="rejectReason"
          placeholder="请填写驳回原因，以帮助用户更好的通过审核"
          :auto-size="{ minRows: 3, maxRows: 10 }"
        />
        <template slot="footer">
          <a-button type="primary" @click="editOk">确认</a-button>
          <a-button @click="editCancel">取消</a-button>
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      pass: false,
      edit: false,
      state: "",
      checked: false,
      rejectReason: "",
      data: {},
      rotation: 0,
      tableData: [], //课程证书模板
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),

      certificateCode: "", //证书编号
      certificateDate: undefined, // 发证日期
      certifTempId: "", //证书模板编号
      queryParams: {
        type: 1, // 模板类型 1.考试模板 2.课程模板
        pageNum: 1,
        pageSize: 100,
      },
    };
  },
  // 事件处理器
  methods: {
    rotateImage() {
      this.rotation += 90; // 每次点击旋转90度
    },
    ondateChange(date, dateString) {
      // console.log(date, dateString);
      this.certificateDate = dateString;
    },
    // 证书模板选择
    handleChange(value) {
      // console.log(`selected ${value}`);
      this.certifTempId = value;
    },
    onChange(e) {
      this.checked = e.target.checked;
    },
    passOk(e) {
      if (!this.certificateCode) {
        this.$message.warning("请输入证书编号");
        return;
      }
      if (!this.certificateDate) {
        this.$message.warning("请输入发证日期");
        return;
      }
      if (!this.certifTempId) {
        this.$message.warning("请选择证书模板");
        return;
      }
      this.pass = false;
      this.state = 3;
      this.onExamine();
    },
    passCancel(e) {
      this.pass = false;
    },
    editOk(e) {
      this.state = 4;
      if (!this.rejectReason) {
        this.$message.warn("请填写驳回原因");
        return false;
      } else {
        this.edit = false;
        this.onExamine();
      }
    },
    editCancel(e) {
      this.edit = false;
    },
    // 查询证书模板列表
    getManageList() {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/certiftemp/getSelection",
        params: this.queryParams,
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 200 && res.success) {
            this.tableData = res.data.records;
          } else {
            this.tableData = [];
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error) {
            this.$message.warning("系统错误");
          }
        });
    },
    onExamine() {
      if (this.state != 3 && this.state != 4) {
        this.state = 2;
      }
      this.$ajax({
        url: "/hxclass-management/course/buy-audit/approve",
        method: "put",
        params: {
          courseBuyAuditId: this.data.courseBuyAuditId,
          courseName: this.data.courseName,
          rejectReason: this.rejectReason,
          // modifier: this.modifier,//修改者
          status: this.state,
          userId: this.data.userId,
          certificateCode: this.certificateCode,
          certificateDate: this.certificateDate ? (this.certificateDate + '-01'):"",
          certifTempId: this.certifTempId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.$router.push("/admin/AuditManage/CoursePurchase");
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    getDetailList() {
      this.$ajax({
        url: "/hxclass-management/course/buy-audit/" + this.id,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.data = res.data;
          this.certificateCode = res.data.code;
          this.checked = this.data.status == 3 ? true : false;
          if (this.data.userType == 1) {
            this.data.userType = "家长";
          } else if (this.data.userType == 2) {
            this.data.userType = "从业者";
          } else if (this.data.userType == 3) {
            this.data.userType = "机构";
          } else if (this.data.userType == 4) {
            this.data.userType = "家长+从业者";
          } else if (this.data.userType == 5) {
            this.data.userType = "家长+机构 ";
          } else if (this.data.userType == 6) {
            this.data.userType = "从业者+机构";
          } else if (this.data.userType == 7) {
            this.data.userType = "家长+从业者+机构";
          }
        } else {
          this.$message.warning(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.id = this.$route.query.id;
    this.getDetailList();
    this.getManageList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    // checked(newval) {
    //   if (newval == true) {
    //     this.state = 3;
    //   } else {
    //     this.state = "";
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.Up_Down_inner {
  margin-bottom: 10px;
  .all_left_name {
    font-weight: 500;
    color: #000000;
  }
  .check {
    font-size: 14px;
    margin-top: 15px;
    i {
      font-style: normal;
      color: #ff0000;
    }
  }
}
.bgDiv {
  .line_item {
    display: flex;
    margin-bottom: 24px;
    p {
      width: calc(100% / 3);
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      span {
        font-weight: 500;
        color: #333333;
      }
    }
  }
  .line_item:last-child {
    margin-bottom: 0;
  }
}
.certificateDiv {
  p {
    font-size: 14px;
    margin-top: 15px;
  }
  img {
    width: 420px;
  }
}
.foot_btn {
  margin-top: 20px;
  .btn {
    margin-right: 20px;
  }
}
.passtext {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 14px;
}
.dlex {
  width: 700px;
  > .image {
    height: 700px;
    display: flex;
    position: relative;
    align-items: center;
    width: 700px;
    img {
      max-height: 700px;
      width: 700px;
      z-index: 12;
    }
    .btnforat {
      position: absolute;
      right: 0;
      z-index: 13;
      bottom: 30px;
    }
  }

  .form {
    width: 100%;
  }
}
.dlexname {
  display: flex;
  align-items: center;
  > span {
    margin-bottom: 24px;
  }
}
</style>
